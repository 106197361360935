export const GetFinam = async () => {
	const apiUrl: string = process.env.NEXT_PUBLIC_IS_DEV ?? '';
	return await fetch(
		`${
			Number(apiUrl) == 1
				? '/api?action=currency'
				: 'https://smi2.ru/newdata/jsapi?action=currency'
		}`,
		{
			method: 'POST',
			cache: 'force-cache',
			next: { revalidate: 120 },
			mode: 'cors',
			body: JSON.stringify({
				block_id: 84683
			}),
			headers: new Headers({ 'content-type': 'application/json' })
		}
	);
};
